import React from 'react'

import { Button } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import Link from '~/components/base/Link'
import Text from '~/components/base/Text'
import TextBg from '~/components/common/TextBg'

import ROUTES from '~/utils/constants/routes'

import Icon from '~/assets/icons/home/oval.svg'

import SectionContainer from './SectionContainer'

const JoinSection = () => {
  return (
    <SectionContainer sx={{ pb: { xs: 5, md: 7 }, textAlign: 'center' }}>
      <Text size={{ xs: 'h3', md: 'h1' }} fontWeight={700} mb={3.5}>
        <FormattedMessage
          defaultMessage="Do you make cakes too? {br}<text>Join us</text>"
          values={{
            br: <br />,
            text: (children: string) => (
              <TextBg
                sx={{ '& svg': { width: { xs: 165, md: 265 } } }}
                icon={<Icon />}
                positionIcon={{
                  top: { xs: -19, md: -2 },
                  left: { xs: -40, md: -60 }
                }}
              >
                {children}
              </TextBg>
            )
          }}
        />
      </Text>

      <Link prefetch={false} href={ROUTES.subscription}>
        <Button
          LinkComponent={Link}
          variant="contained"
          sx={{
            width: { xs: '100%', sm: 'auto' },
            py: 1.8,
            px: 1.5,
            fontSize: 16,
            fontWeight: 600,
            whiteSpace: 'nowrap'
          }}
        >
          <FormattedMessage defaultMessage="Try for free" />
        </Button>
      </Link>
    </SectionContainer>
  )
}

export default JoinSection
